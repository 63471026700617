<template>
  <div>
    <div
      class="bg-center bg-no-repeat bg-cover lg:bg-top"
      style="background-image: url('/images/bg-banner.jpg')"
    >
      <div class="container py-32 lg:py-44 xl:py-52"></div>
      <div class="relative -bottom-20">
        <div class="w-11/12 px-2 py-10 md:w-4/5 xl:w-2/3 bg-sri-orange">
          <h1 class="text-lg font-semibold leading-tight text-gray-700 md:text-xl">
            Reputable Maid Agency in Malaysia for
          </h1>
          <h1 class="mt-2 text-2xl font-bold text-gray-900 md:text-4xl">
            Philippines &amp; Indonesia Domestic Worker
          </h1>
          <h1 class="mt-4 font-semibold text-gray-700 md:text-xl">
            If You Are Looking For
          </h1>
          <p class="pt-2 text-sm text-gray-700">
            House Maid | General Worker | Want Your Old Maid/ Worker Back?
          </p>
          <h2 class="pt-4 text-xl font-bold lg:text-2xl">Let Us Maid Up Your Life</h2>
          
          <div class="flex mt-4 space-x-1">
            <h1
              class="py-2 font-semibold text-center text-gray-600 transition duration-200 ease-linear transform border border-gray-600 rounded-md w-36 hover:border-white hover:bg-white hover:text-gray-800 hover:scale-105"
            >
              <a href="#" v-scroll-to="{ el: '#element', duration: 1500 }"
                >Enquire Us Now</a
              >
            </h1>
            <h1
              class="py-2 font-semibold text-center text-gray-600 transition duration-200 ease-linear transform border border-gray-600 rounded-md hover:border-white w-36 hover:bg-white hover:text-gray-800 hover:scale-105"
            >
              <a href="https://wa.me/60163399733?text=Hi%20there,%20I%20would%20like%20to%20enquiry%20about%20Philippines%20and%20Indonesia%20maid.">WhatsApp Us</a>
            </h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hideEnquiry: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
